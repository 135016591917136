<template>
  <div>
      Zde bude madafaka log
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  
  components: {

  },
    metaInfo () { 
      return { title: this.$t("log.meta.title")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("log.meta.title") }]);
  },
  methods: {
  
  }
};
</script>
